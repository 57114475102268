// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-layout-js": () => import("./../../../src/components/blog-layout.js" /* webpackChunkName: "component---src-components-blog-layout-js" */),
  "component---src-components-layout-js": () => import("./../../../src/components/layout.js" /* webpackChunkName: "component---src-components-layout-js" */),
  "component---src-pages-blog-archive-js": () => import("./../../../src/pages/blog/archive.js" /* webpackChunkName: "component---src-pages-blog-archive-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */)
}

